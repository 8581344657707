<ng-container *transloco="let t; prefix: 'snackbar'">
  <section class="notification">
    @if (data) {
      <div>
        <p class="white sans">{{ data.message }}</p>
        <p class="sans">
          <small
            ><a [routerLink]="[data.link]" (click)="close()">{{
              t('seeMessage', {firstName: data.firstName, lastName: data.lastName})
            }}</a></small
          >
        </p>
      </div>
      <ess-avatar size="md" [image]="data.photoUrl" [firstName]="data.firstName" [lastName]="data.lastName" />
    }
  </section>
</ng-container>
