import { Component, Inject } from '@angular/core';

import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MessageNotificationData } from '@shared/mocks/notification-data.model';
import { AvatarComponent } from '@shared/avatar/avatar.component';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-message-notification',
  standalone: true,
  imports: [AvatarComponent, RouterLink, MatSnackBarLabel, MatSnackBarAction, MatSnackBarActions, TranslocoDirective],
  templateUrl: './message-notification.component.html',
  styleUrl: './message-notification.component.scss',
})
export class MessageNotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: MessageNotificationData,
    private snackRef: MatSnackBarRef<MessageNotificationComponent>,
  ) {}

  close() {
    this.snackRef.dismiss();
  }
}
