import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MessageNotificationComponent } from '@shared/notification/message-notification.component';
import { MessageNotificationData } from '@shared/mocks/notification-data.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'start',
    verticalPosition: 'bottom',
    panelClass: 'ess-snackbar',
    duration: 6000,
  };
  constructor(private snackBar: MatSnackBar) {}

  open(message: string) {
    this.snackBar.open(message, '', this.snackBarConfig);
  }

  openAndAction$(message: string, action: string): Observable<void> {
    return this.snackBar.open(message, action, this.snackBarConfig).onAction();
  }

  openMessageNotification(data: MessageNotificationData) {
    this.snackBar.openFromComponent(MessageNotificationComponent, {
      ...this.snackBarConfig,
      duration: 10000,
      data,
    });
  }
}
