export enum EssDateFormat {
  Hour = 'essHour',
  HourLong = 'essHourLong',
  HourShort = 'essHourShort',
  Long = 'essLong',
  Short = 'essShort',
  Standard = 'essStandard',
}

export enum SupportedLanguage {
  En = 'en',
  Es = 'es',
  Pt = 'pt',
}

export const ORDINAL_SUFFIX_LOCATOR = '*';

export const ESS_DATE_FORMAT_STRING: Record<EssDateFormat, Record<SupportedLanguage, string>> = {
  essHour: {
    en: 'MMM d y · h:mm a',
    es: "d 'de' MMM, y · HH:mm",
    pt: "d 'de' MMM, y · HH:mm",
  },
  essHourLong: {
    en: 'EEEE, MMM d y · h:mm a',
    es: "EEEE, d 'de' MMM y · HH:mm",
    pt: "EEEE, d 'de' MMM y · HH:mm",
  },
  essHourShort: {
    en: 'h:mm a',
    es: 'HH:mm',
    pt: 'HH:mm',
  },
  essLong: {
    en: `EEEE, MMMM d, yyyy`,
    es: "EEEE, d 'de' MMMM, yyyy",
    pt: "EEEE, d 'de' MMMM, yyyy",
  },
  essShort: {
    en: `MMMM d'${ORDINAL_SUFFIX_LOCATOR}'`,
    es: "d 'de' MMMM",
    pt: "d 'de' MMMM",
  },
  essStandard: {
    en: `EEEE, MMMM d'${ORDINAL_SUFFIX_LOCATOR}'`,
    es: "EEEE, d 'de' MMMM",
    pt: "EEEE, d 'de' MMMM",
  },
};
