import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizeImagePipe } from '@ess-front/shared';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-avatar',
  standalone: true,
  imports: [CommonModule, ResizeImagePipe, TranslocoDirective],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input() firstName: string | null = '';
  @Input() image: string | null = '';
  @Input() isAmex = false;
  @Input() isDarkAvatar = false;
  @Input() lastName: string | null = '';
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';

  memberInitials: string | null = null;
  readonly IMAGE_SIZE = 114;

  ngOnChanges(): void {
    const firstName = this.firstName ?? '';
    const lastName = this.lastName ?? '';
    this.memberInitials = !firstName && !lastName ? '?' : firstName.charAt(0) + lastName.charAt(0);
  }
}
