<ng-container *transloco="let t; prefix: 'itinerary'">
  <div>
    @if (image && !isAmex) {
      @defer (on idle) {
        <img
          [src]="image | resizeImg: IMAGE_SIZE : IMAGE_SIZE : 'c_fill'"
          alt="{{ t('avatarImage') }}"
          [class]="size"
        />
      } @placeholder {
        <ng-template [ngTemplateOutlet]="initials" />
      }
    } @else {
      <ng-template [ngTemplateOutlet]="initials" />
    }
  </div>

  <ng-template #initials>
    <span [class]="size" [ngClass]="{dark: isDarkAvatar}">{{ memberInitials }}</span>
  </ng-template>
</ng-container>
